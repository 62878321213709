import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';

const TimeoutContainer = ({ duration = 5000, children }) => {
  const [show, setShow] = useState(true);

  useEffect(() => {
    let timeout;
    if (show) {
      timeout = setTimeout(() => {
        setShow(false);
      }, duration);
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [duration, show, setShow]);
  return show ? <>{children}</> : null;
};

export default TimeoutContainer;
